// import "slick.min.js";
jQuery(function($){
    $(document).ready(function(){
        // прилипание меню по скроллу
        $(function() {
            let header = $('.bottom-header');
            let menuHeight = header.height();
            let headerHeight = $('header').height();

            $(window).scroll(function() {
                if($(this).scrollTop() > headerHeight) {
                    header.attr('data-js','fixed');
                    $('body').css({
                        'paddingTop': menuHeight+'px'
                    });
                } else {
                    header.attr('data-js','');
                    $('body').css({
                        'paddingTop': 0
                    })
                }
            });
        });

        // Slider-top
        $(function(){
            $('.js-top-slider').slick();
        });

        // Slider-tv
        $(function(){
            $('.js-tv-slider').slick({
                infinite: true,
                appendArrows: $('.js-tv-slider-nav'),
                slidesToShow: 9,
                slidesToScroll: 7,
                responsive: [
                    {
                        breakpoint: 1199,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 3,
                            infinite: true,
                            dots: false,
                        }
                    },
                    {
                        breakpoint: 660,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }
                    }

                ]
            });
        })

        //кнопка "читать дальше"
        $('.js-hide-text_btn').on('click', function (){
            $('.js-hide-text').css('max-height', 'none');
            $(this).hide();
        });

        //
        $('.js-menu-toggle').on('click', function (){
            let status = $('.js-mobile-menu').attr('data-js');
            if(status == 'open'){
                $('.js-mobile-menu').attr('data-js','close');
            } else if(status == 'close'){
                $('.js-mobile-menu').attr('data-js','open');
            } else{
                $('.js-mobile-menu').attr('data-js','open');
            }
        });

        //подгрузка игр
        $('.js-play-game').on('click', function (){
            let link = $(this).data('link');
            $('iframe').attr('src', link);
            $(this).remove();
        });


        //скрипт для рефералок
        $('[data-link]').on('click', function (){
            let link = atob($('[data-link]').data('link'));
            let getUrl = window.location;
            let home = getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
            window.location.replace(home+link)
        });
    });
});







